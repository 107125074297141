import React from "react";
import OrderTracker from "./components/OrderTracker";
import "./App.css";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <OrderTracker />
    </div>
  );
}

export default App;
