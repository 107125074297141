import React, { useState } from "react";
import "./OrderTracker.css";

const OrderTracker = () => {
  const [currentOrder, setCurrentOrder] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [searchOrder, setSearchOrder] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewMode, setViewMode] = useState("update");

  const handleUpdateStatus = async () => {
    if (!currentOrder || !currentStatus) return;

    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        `http://localhost:3001/api/orders/${currentOrder}/status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: currentStatus }),
        }
      );

      const data = await response.json();
      if (data.success) {
        setCurrentOrder("");
        setCurrentStatus("");
      } else {
        setError("Failed to update order status");
      }
    } catch (err) {
      setError("Failed to update order status");
    } finally {
      setLoading(false);
    }
  };

  const handleTrackOrder = async () => {
    if (!searchOrder) return;

    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        `http://localhost:3001/api/orders/${searchOrder}`
      );
      const data = await response.json();

      if (data.success) {
        setOrderDetails(data.data);
      } else {
        setError("Order not found");
        setOrderDetails(null);
      }
    } catch (err) {
      setError("Failed to fetch order details");
      setOrderDetails(null);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Processing":
        return "blue";
      case "In Transit":
        return "orange";
      case "Delivered":
        return "green";
      default:
        return "gray";
    }
  };

  return (
    <div className="order-tracker">
      <div className="view-toggle">
        <button
          className={viewMode === "update" ? "active" : ""}
          onClick={() => setViewMode("update")}
        >
          Update Orders
        </button>
        <button
          className={viewMode === "track" ? "active" : ""}
          onClick={() => setViewMode("track")}
        >
          Track Order
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}

      {viewMode === "update" ? (
        <div className="card">
          <h2>Update Order Status</h2>
          <div className="form-group">
            <input
              type="text"
              placeholder="Order Number"
              value={currentOrder}
              onChange={(e) => setCurrentOrder(e.target.value)}
              disabled={loading}
            />
            <select
              value={currentStatus}
              onChange={(e) => setCurrentStatus(e.target.value)}
              disabled={loading}
            >
              <option value="">Select Status</option>
              <option value="Processing">Processing</option>
              <option value="In Transit">In Transit</option>
              <option value="Delivered">Delivered</option>
            </select>
            <button
              onClick={handleUpdateStatus}
              disabled={loading}
              className="primary-button"
            >
              {loading ? "Updating..." : "Update Status"}
            </button>
          </div>
        </div>
      ) : (
        <div className="card">
          <h2>Track Your Order</h2>
          <div className="form-group">
            <div className="search-group">
              <input
                type="text"
                placeholder="Enter Order Number"
                value={searchOrder}
                onChange={(e) => setSearchOrder(e.target.value)}
                disabled={loading}
              />
              <button
                onClick={handleTrackOrder}
                disabled={loading}
                className="primary-button"
              >
                {loading ? "Searching..." : "Track"}
              </button>
            </div>

            {orderDetails && (
              <div className="order-details">
                <div className="current-status">
                  <span
                    className="status-badge"
                    style={{
                      backgroundColor: getStatusColor(
                        orderDetails.current_status
                      ),
                    }}
                  >
                    {orderDetails.current_status}
                  </span>
                </div>

                <div className="status-timeline">
                  {orderDetails.updates.map((update, index) => (
                    <div key={index} className="timeline-item">
                      <div className="timeline-dot"></div>
                      <div className="timeline-content">
                        <div className="status">{update.status}</div>
                        <div className="timestamp">
                          {new Date(update.timestamp).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderTracker;
